import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import images from "../assets/images";
import Logo from "../assets/geobuzz-logo-geel-programma.webp";

const PresentatieCard = (props) => {
    const [imageName, setImageName] = useState(props.presentationObject.afbeelding);
    const [specificStyle, setSpecificStyle] = useState({});

    useEffect(() => { if (props.presentationObject.datum == "26 november") {
        setSpecificStyle({
            backgroundColor: "#266332"
        })
    }
    }, []);

    return (
        <div className="card" style={specificStyle}>
            <div className="card__preview">
                <img src={imageName ? images[imageName] : Logo}/>
                <p className="card__date">{props.presentationObject.datum} | {props.presentationObject.tijdstip}</p>
            </div>
            <div className="card__content">
                <h3>{props.presentationObject.titel}</h3>
                <p className="card__location">{props.presentationObject.locatie}</p>
                <p className="card__speaker">{props.presentationObject.spreker}</p>
                <p className="card__company">{props.presentationObject.organisatie}</p>
                <div className="card__bottom">
                    <Link to={props.presentationObject["Presentatie ID"]} className="card__link">{props.presentationObject.knoptekst}</Link>
                </div>
            </div>

        </div>

    )
}

export default PresentatieCard;