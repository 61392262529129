import React, { useState } from "react";
import Hero from "../components/Hero";
import PresentatieCard from "../components/PresentatieCard";
import fallbackSrc from "../assets/Logo-geobuzz-geel.jpg";

const dateFilterOptions = ["25 november", "26 november"];
const timeFilterOptions = ["12.00 - 12.45", "13.45 - 14.30", "15.00 - 15.45"];

const Programma = (props) => {
    // Houdt de geselecteerde filters bij voor beide categorieën, nu als één enkele waarde in plaats van een array
    const [selectedDateFilter, setSelectedDateFilter] = useState(null);
    const [selectedTimeFilter, setSelectedTimeFilter] = useState(null);

    // Functie om een categorie filter te selecteren (één tegelijk)
    const selectDateFilter = (filter) => {
        setSelectedDateFilter((prevFilter) => (prevFilter === filter ? null : filter));
    };

    // Functie om een merk filter te selecteren (één tegelijk)
    const selectTimeFilter = (filter) => {
        setSelectedTimeFilter((prevFilter) => (prevFilter === filter ? null : filter));
    };

    // Filter de data op basis van de geselecteerde categorie en merk filters
    const filteredData = props.data.filter((presentation) => {
        const dateMatch = !selectedDateFilter || selectedDateFilter === presentation.datum;
        const timeMatch = !selectedTimeFilter || selectedTimeFilter === presentation.tijdstip 
        || (selectedTimeFilter === "12.00 - 12.45" && presentation.tijdstip === "12.00 - 12.20") 
        || (selectedTimeFilter === "12.00 - 12.45" && presentation.tijdstip === "12.25 - 12.45")
        || (selectedTimeFilter === "13.45 - 14.30" && presentation.tijdstip === "13.45 - 14.05")
        || (selectedTimeFilter === "13.45 - 14.30" && presentation.tijdstip === "14.10 - 14.30")
        || (selectedTimeFilter === "15.00 - 15.45" && presentation.tijdstip === "15.00 - 15.20")
        || (selectedTimeFilter === "15.00 - 15.45" && presentation.tijdstip === "15.25 - 15.45");
        return dateMatch && timeMatch; 
    });

    console.log(filteredData);

    /*
    const [filterObject, setFilterObject] = useState();

    const filterReset = (e) => {
        console.log(e.target.value);
        setFilterObject();
    }

    const handleClickDate = (e) => {
        const filterArray = props.data.filter(presentation => presentation.datum == e.target.value)
        setFilterObject(...filterObject, filterArray);
    };

    const handleClickTime = (e) => {
        const filterArray = props.data.filter(presentation =>
            presentation.tijdstip == e.target.value)
        if (e.target.value == "12.00 - 12.45") {
            props.data.forEach(presentation => {
                if (presentation.tijdstip == "12.00 - 12.20") {
                    filterArray.push(presentation);
                } else if (presentation.tijdstip == "12.25 - 12.45") {
                    filterArray.push(presentation);
                }
            })
        } else if (e.target.value == "13.45 - 14.30") {
            props.data.forEach(presentation => {
                if (presentation.tijdstip == "13.45 - 14.05") {
                    filterArray.push(presentation);
                } else if (presentation.tijdstip == "14.10 - 14.30") {
                    filterArray.push(presentation);
                }
            })
        } else if (e.target.value == "15.00 - 15.45") {
            props.data.forEach(presentation => {
                if (presentation.tijdstip == "15.00 - 15.20") {
                    filterArray.push(presentation);
                } else if (presentation.tijdstip == "15.25 - 15.45") {
                    filterArray.push(presentation);
                }
            })
        }

        setFilterObject(filterArray);
    };

    <button type="button" onClick={() => setSelectedFilters([])} value="Alle">Alle</button>
    */

    return (
        <div className="container-programma">
            <Hero heroTitle={"Programma"} />
            <div className="header_info1">
                <h2>Bekijk het programma</h2>
                <p>Hieronder vindt u het programma voor 25 & 26 november. Het thema deze editie is 'Geodata: niet uniek, wel essentieel.<br />
                    U kunt de presentaties filteren via onderstaande knoppen. U kunt één datum en tijdstip selecteren. Als u niks selecteert worden alle presentaties getoond.</p>
            </div>
            <div className="filter">
                <div>
                    <h3>Filter hier op datum</h3>
                    {dateFilterOptions.map((filter) => (
                        <button
                            key={filter}
                            onClick={() => selectDateFilter(filter)}
                            style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "#fcc30e",
                                backgroundColor: selectedDateFilter === filter ? "white" : "#fcc30e",
                                color: selectedDateFilter === filter ? "#fcc30e" : "white",
                            }}
                        >
                            {filter}
                        </button>
                    ))}
                </div>
                <div>
                    <h3>Filter hier op tijdstip</h3>
                    {timeFilterOptions.map((filter) => (
                        <button
                            key={filter}
                            onClick={() => selectTimeFilter(filter)}
                            style={{
                                borderWidth: 1,
                                borderStyle: "solid",
                                borderColor: "#fcc30e",
                                backgroundColor: selectedTimeFilter === filter ? "white" : "#fcc30e",
                                color: selectedTimeFilter === filter ? "#fcc30e" : "white",
                            }}
                        >
                            {filter}
                        </button>
                    ))}
                </div>
            </div>

            <div className="cointainer-card">
                {
                    filteredData.map(presentation => {
                        return (
                            <PresentatieCard
                                key={presentation["Presentatie ID"]}
                                presentationObject={presentation}
                                imageSource={presentation.afbeelding}
                                fallbackSrc={fallbackSrc}
                            />
                        )
                    })
                }
                {/*
                {
                    filterObject ?
                        filterObject.map(presentation => {
                            return (
                                <PresentatieCard
                                    key={presentation["Presentatie ID"]}
                                    presentationObject={presentation}
                                    imageSource={presentation.afbeelding}
                                    fallbackSrc={fallbackSrc}
                                />
                            )
                        })
                        :
                        props.data.map(presentation => {
                            return (
                                <PresentatieCard
                                    key={presentation["Presentatie ID"]}
                                    presentationObject={presentation}
                                    imageSource={presentation.afbeelding}
                                    fallbackSrc={fallbackSrc}
                                />
                            )
                        })
                }
                */}
            </div>
        </div>
    )
}

export default Programma;