import React, { useState } from "react";
import { Form } from "react-router-dom";

const NieuwsbriefAanmelden = () => {
    const [newsletterFormData, setNewsletterFormData] = useState({
        email: "",
        voornaam: ""
    });

    const [dataSent, setDataSent] = useState(false);

    const handleInputChange = (e) => {
        console.log("open change");
        const { name, value } = e.target;
        setNewsletterFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        console.log("handle submit fuction open");
        e.preventDefault();
        console.log(newsletterFormData);
        fetch("https://geobuzz.nl/api/newsletter", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(newsletterFormData)
          }).then((response) => {
            console.log("fetch call gedaan");
            setNewsletterFormData({email: "", voornaam: ""});
            console.log(response);
            if (response.ok) {
                setDataSent(true);
            }
        });
    } 

    /*
    async function handleSubmit(e) {
        const url = "https://esp.sense-events.nl/api/transactional/send";
        try {
            const response = await fetch(url, {
                method: "POST",
                headers: {
                    "Content-Type": "application/json",
                    "X-Auth-APIKey": "e2b5mG6ZC48Z2P2eRYwctN"
                },
                body: JSON.stringify({
                    "fromname": "GeoBuzz",
                    "fromemail": "info@geobuzz.nl",
                    "to": "info@geobuzz.nl",
                    "subject": "Nieuwe aanmelding nieuwsbrief",
                    "replyto": `${newsletterFormData.email}`,
                    "body": `<html><body>E-mail: ${newsletterFormData.email}<br> Voornaam: ${newsletterFormData.voornaam}</body></html>`,
                    "tag": "nieuwsbriefnieuw"
                })
            });
            if (!response.ok) {
                throw new Error(`Response status: ${response.status}`);
            }

            const json = await response.json();
            console.log(json);
        } catch (error) {
            console.error(error.message);
        }
    }
    */

    return (
        <div className="newsletter" id="newsletter">
            <h2>Inschrijven Nieuwsbrief</h2>
            <form onSubmit={handleSubmit}>
                <label for="email">E-mailadres</label>
                <input
                    type="text"
                    name="email"
                    id="email"
                    value={newsletterFormData.email}
                    onChange={handleInputChange}
                />
                <label for="voornaam">Voornaam</label>
                <input
                    type="text"
                    name="voornaam"
                    id="voornaam"
                    value={newsletterFormData.voornaam}
                    onChange={handleInputChange}
                />
                <input type="submit" />
            </form>
            {
                dataSent && (<p>Bedankt voor de aanmelding. We houden u op de hoogte van het laatste nieuws.</p>)
            }
        </div>
    )
};

export default NieuwsbriefAanmelden;