import React, { useState } from "react";
import Hero from "../components/Hero";
import Map2D from "../assets/GeoBuzz-Plattegrond-schaal-2025.webp";
import Map3D from "../assets/geobuzz-plattegrond-3D.webp";
import { Link } from "react-router-dom";

const Beurs = () => {
    const [map, setMap] = useState(Map2D);
    const toggle2DMap = () => {
        setMap(Map2D);
    };

    const toggle3DMap = () => {
        setMap(Map3D);
    };

    return (
        <div className="container-beurs">
            <Hero heroTitle={"Beurs"} />
            <h2 className="header_info1">Plattegrond</h2>
            <p className="info_beurs">Hieronder ziet u de beursplattegrond voor 17 en 18 november 2025. De standnummers waar nog geen naam achter staat
                zijn nog beschikbaar. <Link to="/reserveren">Reserveer hier</Link> een beursplaats.</p>
            <div className="buttonMap">
                <button onClick={toggle2DMap} value="Map2D">2D</button>
                <button onClick={toggle3DMap} value="Map3D">3D</button>
            </div>
            <img src={map} className="map" />
        </div>
    )
}

export default Beurs;