import React, { useEffect } from "react";
import Foto1 from "../assets/geobuzz-home-1.webp";
import NieuwsbriefAanmelden from "../components/NieuwsbriefAanmelden";
import Hero from "../components/Hero";

import { Link, useLocation } from "react-router-dom";
import VideoStream from "../components/VideoStream";

const Home = () => {
    const { hash } = useLocation();
    console.log(hash);

    useEffect(() => {
      if (hash) {
        const element = document.querySelector(hash);
        if (element) {
          element.scrollIntoView({ behavior: "smooth" });
        }
      }
    }, [hash]);

    return (

        <div className="container-home">
            <Hero heroTitle={"GeoBuzz"} />


            <div className="header_info1">
                <h2>OVER GEOBUZZ</h2>
                <div className="separator"></div>
            </div>

            <p className="info1">GeoBuzz is het jaarlijkse congres van de verenigingen GIN (GeoInformatie Nederland) en GBN (GeoBusiness Nederland).<br /><br />

                Op de beurs ontmoet u bedrijven en organisaties die geodata inwinnen, delen en gebruiken.
                Zij gaan graag in gesprek met u over de diensten en producten die zij aanbieden.
                De geosector is essentieel voor veel maatschappelijke thema&apos;s, voor beleid en uitvoering er van.
                Laat u bijpraten en verrassen.<br /><br />

                GeoBuzz is het evenement waar vakgenoten in de geosector elkaar ontmoeten, maar ook voor gebruikers van geodata. Ontdek wat dit voor u kan betekenen.
                Deelname aan GeoBuzz is kosteloos.<br /><br />

                Kom naar GeoBuzz en ontdek de mogelijkheden van geodata.
            </p>

            <div className="counter">
                <div>
                    <h2>56</h2>
                    <p>exposanten</p>
                </div>
                <div>
                    <h2>2000</h2>
                    <p>Deelnemers</p>
                </div>
                <div>
                    <h2>50</h2>
                    <p>Presentaties</p>
                </div>
            </div>
            <div className="image1">
                <img src={Foto1} />
            </div>

            <NieuwsbriefAanmelden/>
        </div>
    )
}

export default Home;