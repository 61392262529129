import React, { useState } from "react"
import Hero from "../components/Hero";

const Streamtheater = () => {
    const [beursFormData, setBeursFormData] = useState({
        email: "",
        naam: "",
        organisatie: "",
        standplaats: "",
        standnummer: ""
    });

    const [dataSent, setDataSent] = useState(false);

    const handleInputChange = (e) => {
        console.log("open change");
        const { name, value } = e.target;
        setBeursFormData((prevFormData) => ({
            ...prevFormData,
            [name]: value,
        }));
    };

    const handleSubmit = (e) => {
        e.preventDefault();
        console.log(beursFormData);
        fetch("https://geobuzz.nl/api/beursplaats", {
            method: "POST",
            headers: {
                "Content-type": "application/json"
            },
            body: JSON.stringify(beursFormData)
        }).then((response) => {
            console.log("fetch call gedaan");
            setBeursFormData({
                email: "",
                naam: "",
                organisatie: "",
                standplaats: "",
                standnummer: ""
            });
            console.log(response);
            if (response.ok) {
                setDataSent(true)
            };
        });
    }

    return (
        <div className="streamtheater__container">
            <Hero heroTitle="Streamtheater reserveren" />
            <h2 className="streamtheater__header">Streamtheater plek reserveren</h2>
            <div className="streamtheater__form">
                <p>Er zijn twee tijdsloten op 25 november beschikbaar gekomen in het Streamtheater: 15.00 - 15.20 en 15.25 - 15.45. De presentaties in het Streamtheater zijn op de dag zelf bij te wonen, worden live uitgezonden via onze website en opgenomen. Na het evenement staat de opname online en ontvangt u deze.
                    Een nieuw product, project of ontwikkeling onder de aandacht brengen? Meld u nu aan via onze website of stuur ons een bericht en presenteer nog op de aankomende editie.</p>
                <p>De kosten per tijdslot zijn €225,- excl. btw.</p>


                <form onSubmit={handleSubmit}>
                    <label for="email">E-mailadres</label>
                    <input
                        type="text"
                        name="email"
                        id="email"
                        value={beursFormData.email}
                        onChange={handleInputChange}
                    />
                    <label for="voornaam">Naam</label>
                    <input
                        type="text"
                        name="naam"
                        id="naam"
                        value={beursFormData.naam}
                        onChange={handleInputChange}
                    />
                    <label for="organisatie">Organisatie</label>
                    <input
                        type="text"
                        name="organisatie"
                        id="organisatie"
                        value={beursFormData.organisatie}
                        onChange={handleInputChange}
                    />
                    <label for="standplaats">Welke tijdslot wenst u?</label>
                    <select name="standplaats" value={beursFormData.standplaats} onChange={handleInputChange}>
                        <option value="15:00 - 15:20">25 november: 15:00 - 15:20</option>
                        <option value="15:25 - 15:45">25 november: 15:25 - 15:45</option>
                    </select>
                    <input type="submit" />
                </form>
                {
                    dataSent && (<p>Bedankt voor de reservering. We nemen zo snel mogelijk contact met u op</p>)
                }
            </div>
        </div>
    )
}

export default Streamtheater;