import React from 'react';
import { Link } from 'react-router-dom';

const Hero = ({heroTitle}) => {
return (
    <header className="hero">
        <h1>{heroTitle}</h1>
        <h2>17 & 18 november 2025 | Congrescentrum 1931 &#39;s-Hertogenbosch</h2>
        <h2>09.00 - 17.00 uur</h2>
        <Link to="/#newsletter" className="button">Inschrijven nieuwsbrief</Link>
    </header>
)
}

export default Hero;