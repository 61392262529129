import React from "react";

const VideoStream = () => {
    return (
        <div className="responsive-video">
        <iframe
            src="https://www.youtube.com/embed/vpmYYlJiqJQ?si=Iq9d6-DOj0x83Kfm"
            title="YouTube video player"
            frameborder="0"
            allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
            referrerpolicy="strict-origin-when-cross-origin"
            allowfullscreen>
        </iframe>
    </div>
    )
}

export default VideoStream;